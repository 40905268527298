import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {
  getInterpretation,
  getIsLoading,
  getText,
  getDream,
  getDreamId,
} from '../features/dream/dreamSlice';
import {
  getUser,
} from '../features/user/userSlice';
import { WELCOME_ROUTE, DREAM_ROUTE } from '../app/routes';
import Loader from '../components/Loader';
import { crystalFromDream } from '../features/dream/helpers';
import ShareButton from '../components/ShareButton';
import LoginButton from '../components/LoginButton';

function Dream() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dreamId } = useParams();

  const interpretation = useSelector(getInterpretation);
  const text = useSelector(getText);
  const savedDreamId = useSelector(getDreamId);
  const user = useSelector(getUser);

  const isLoading = useSelector(getIsLoading);


  const isPersistedDream = useMemo(() => {
    return !!dreamId;
  }, [dreamId])

  const isLocalDream = useMemo(() => {
    return text && interpretation && !isPersistedDream
  }, [text, interpretation, isPersistedDream])

  useEffect(() => {
    if(!isLocalDream && !isPersistedDream) {
      navigate(WELCOME_ROUTE)
    }
  }, [isPersistedDream, isLocalDream, navigate])

  useEffect(() => {
    if(isPersistedDream) {
      dispatch(getDream(dreamId))
    }
  }, [isPersistedDream, dispatch, dreamId])

  useEffect(() => {
    if(savedDreamId && !dreamId) {

      navigate(`${DREAM_ROUTE}/${savedDreamId}`)

      // silently switch URL to be appropriate
      // (only for the situation in which user just
      // logged in and auto saved dream)
    }
  }, [savedDreamId, dreamId, navigate])

  const interpretationHeroText = useMemo(() => {
    return interpretation.split(".")[0]+"."
  }, [interpretation])

  const interpretationBodyText = useMemo(() => {
    const indexOfFirstPeriod = interpretation.indexOf(".")
    if(indexOfFirstPeriod > 0) {
      return interpretation.substring(indexOfFirstPeriod + 1);
    }
    return interpretation
  }, [interpretation])

  const crystal = useMemo(() => {
    return crystalFromDream(interpretation)
  }, [interpretation])

  if(isLoading) {
    return <Loader />
  }

  return (
    <div className="overflow-y-auto w-full px-[24px] pt-[39px]">
      <p className="interpretation-hero-text w-4/5">{interpretationHeroText}</p>
      <p className="mt-[8px] interpretation-text">{interpretationBodyText}</p>
      <div className="flex flex-row">
        <div className="flex h-[1px] w-4/5 bg-[#FFFFFF]/[0.2] mt-[42px] mb-[29px]"></div>
        <div className="flex w-1/5"><img className="h-[80%] ml-[5px] mt-[16px]" src={crystal} alt="ruby" /></div>
      </div>
      <p className="dream-text w-[85%]">{text}</p>

      {user ?
        <ShareButton /> :
        <LoginButton />}
    </div>
  )
}

export default Dream;
