import React, { useCallback, useState } from 'react';
import { ReactComponent as ShareIcon } from '../assets/icons/share-icon.svg';
import check from '../assets/icons/check.png'

function ShareButton() {

  const [isCopied, setIsCopied] = useState(false);

  const share = useCallback((e) => {
    if (navigator.share) {
      navigator
        .share({
          title: "Dream interpretation",
          text: `Check out my dream interpretation on Nightcap!`,
          url: document.location.href,
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch(error => {
          console.error('Something went wrong sharing the blog', error);
        });
    } else {

      const toCopy = window.location.href

      navigator.clipboard.writeText(toCopy);
      setIsCopied(true);
    }
    e.preventDefault()
  }, [])


  return (<button
    onClick={share}
    onAnimationEnd={() => setIsCopied(false)}
    className={`flex-row
      my-[53px]
      flex
      ring ring-1 ring-[#FFFFFF]/[0.4]
      rounded-full
      h-[2.5em]
      justify-center items-center
      share-button
      w-full
      ${isCopied ? "share-button-success" : ""}`}
  >
    {isCopied ?
      <img className="h-[55%]" src={check} alt="check" /> :
      <ShareIcon className="h-[55%]" />
    }

    <span className="flex ml-[14px]">
      {isCopied ? "Copied to clipboard!" : "Share"}
    </span>
  </button>)
}

export default ShareButton;
