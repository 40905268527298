import {ReactComponent as AppStoreIcon} from "../assets/download-on-app-store.svg";
import { useNavigate } from "react-router-dom";
import { WELCOME_ROUTE } from '../app/routes';
import Marketing_phone_1 from '../assets/Marketing_phone_1.png';
import Marketing_phone_2 from '../assets/Marketing_phone_2.png';
import Marketing_phone_3 from '../assets/Marketing_phone_3.png';
import NeedHelp from "../features/user/NeedHelp";


function Marketing() {

  const navigate = useNavigate()

  return (
    <div className={"flex flex-col h-full w-full text-center px-[9%]"}>
      <h1 className={"marketing-hero-text mt-[53px]"}>Make sense of your dreams.</h1>
      <p className={"mt-[24px] marketing-subtitle-text max-w-[461px] mx-auto"}>Nightcap instantly interprets your dreams, saves them in a dream journal, and helps you make sense of your life.</p>
      <div
      >
        <button
          className={"w-[172px] h-[58px] mt-[56px]"}
          onClick={() => window.location.href = "https://apps.apple.com/us/app/nightcap-guru/id1662242111"}
        >
          <AppStoreIcon
            className={"w-full h-full"}
          />
        </button>
      </div>
      <button
        className={"mt-[16px] marketing-use-web-button-text"}
        onClick={() => navigate(WELCOME_ROUTE)}
      >
        Use on the web
      </button>
      <div className={"flex flex-row w-full justify-center items-center mt-[16%]"}>
        <img alt={"phone 1"} className={"w-[220px]"} src={Marketing_phone_1} />
        <img alt={"phone 2"} className={"w-[220px] mx-[20px]"} src={Marketing_phone_2} />
        <img alt={"phone 3"} className={"w-[220px]"} src={Marketing_phone_3} />
      </div>
        <div className={"absolute right-0 bottom-0 mr-[20px] mb-[16px]"}>
          <NeedHelp />
        </div>
    </div>
  )
}

export default Marketing
