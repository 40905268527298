function AccountDetails({ subscription }) {
  if(subscription) {
    return (
      <div>
        <p>You have a paid, full access account:</p>
        <p>- Unlimited dream interpretations</p>
        <p>- Unlimited dream sharing</p>
        <p>- Access to dream history</p>
      </div>
    )
  } else {
    return (<div>
      <p>You have a free account, with access to:</p>
      <p>- Unlimited dream interpretations</p>
      <p>- Unlimited dream sharing</p>
    </div>)
  }
}

export default AccountDetails
