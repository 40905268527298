/* eslint no-unused-vars: 0 */  // --> OFF
/* eslint react-hooks/exhaustive-deps: 0 */  // --> OFF
/* eslint jsx-a11y/alt-text: 0 */  // --> OFF

import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUser,
} from '../user/userSlice';
import {
  fetchAllProducts,
  getProducts,
  initCheckout,
  fetchSubscription,
  getSubscription,
  getIsLoadingSubscription
} from './paymentsSlice';
import Loader from '../../components/Loader';
import { getAuth } from "firebase/auth";
import check from '../../assets/icons/check.png'

function PaywallPricing() {

  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const subscription = useSelector(getSubscription);
  const products = useSelector(getProducts);
  const isLoadingSubscription = useSelector(getIsLoadingSubscription);

  const [selected, setSelected] = useState("year")

  const [isBuying, setIsBuying] = useState(false);

  const buy = useCallback((priceId, amount) => {
    if(user) {
      setIsBuying(true)
      const uid = user.uid;
      dispatch(initCheckout({priceId, uid}))
    }
  }, [user, dispatch])

  const clickBuy = useCallback(() => {
    const product = products.find(p => {
      const annualProduct = p.name === "Annual plan"
      const productName = annualProduct ? "year" : "month"
      return productName === selected
    })

    const price = product.prices[0]
    buy(price.priceId, price.amount)
  }, [selected, products, buy])

  useEffect(() => {
    dispatch(fetchAllProducts())
  }, [dispatch])

  useEffect(() => {
    if(user) {
      getAuth().currentUser.getIdToken(true);
      dispatch(fetchSubscription({uid: user.uid}))
    }
  }, [user, dispatch])

  const isAnnual = useCallback((price) => {
    return price.interval === "year"
  }, [])

  const paymentOptions = useCallback((price) => {
    if(isAnnual(price)) {
      return "annually"
    } else {
      return "monthly"
    }
  }, [])

  const pricePerMonth = useCallback((price) => {
    if(isAnnual(price)) {
      return price.amount / 100 / 12
    } else {
      return price.amount / 100
    }
  }, [])

  const durationText = useCallback((price) => {
    if(isAnnual(price)) {
      return "12 months"
    } else {
      return "1 month"
    }
  }, [])

  const descriptionText = useCallback((price) => {
    if(isAnnual(price)) {
      return `1 week free then $${price.amount / 100} / year`
    }
  }, [])

  if(isLoadingSubscription) {
    return (<div>
    </div>)
  }

  if(isBuying) {
    return (<Loader />)
  }

  return (
    <div className={"flex flex-col w-[342px]"}>
      {(products || []).map(p => {
        const annualProduct = p.name === "Annual plan"
        const productName = annualProduct ? "year" : "month"
        const isSelected = selected === productName
        return (
          <button
            className={`relative w-[342px] h-[104px] ring ring-${isSelected ? 5 : 1} ${isSelected ? "ring-[#FFFFFF]" : "ring-[#FFFFFF]/[0.2]"} rounded-[16px] mb-[20px]`}
            onClick={() => setSelected(productName)}
            key={productName}
          >
            <div>
              {p.prices.map(price => {
                return (
                  <div key={price} className={"flex flex-row items-center"}>
                    {isAnnual(price) ? <div
                      className={"absolute top-0 right-0 bg-white w-[107px] h-[27px] flex rounded-tr-[16px] rounded-bl-[16px]"}
                    >
                      <p className={"paywall-best-value-text mx-auto"}>Best value!</p>
                    </div> : null}
                    <div className={"h-[24px] w-[24px] mx-[11px]"}>
                      {isSelected ? <img className={"h-full w-full"} src={check} /> : null}
                    </div>
                    <div className={"w-[296px] h-full flex flex-row"}>
                      <div className={"text-left"}>
                        <p className={"paywall-duration-text"}>{durationText(price)}</p>
                        <p className={"paywall-description-text"}>{descriptionText(price)}</p>
                      </div>
                      <div className={"mr-[11px] justify-center items-center flex ml-auto"}>
                        <p className={"paywall-price-text"}>${pricePerMonth(price)}/mo</p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </button>
        )
      })}
      <button
        className={"w-[342px] h-[56px] bg-[#fff] text-[#1B1C43] rounded-full start-payment-button mt-[4px]"}
        onClick={clickBuy}
      >
        {selected === "year" ? "Start your free trial" : "Start your monthly plan"}
      </button>
    </div>
  )
}

export default PaywallPricing
