import NeedHelpIcon from '../../assets/icons/help_outline.png'

function NeedHelp() {

    return (
        <a href={"mailto:support@nightcap.guru"} className={"need-help-button rounded-full flex-row flex py-[8px] px-[16px]"}>
          <img className={"h-[24px] w-[24px] mr-[10px]"} src={NeedHelpIcon} alt={"need help icon"} />
          <p className={"need-help-text"}>Need help?</p>
        </a>
    )
}

export default NeedHelp