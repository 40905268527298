import React, { useMemo } from 'react';
import { DREAM_ROUTE } from '../../app/routes';
import { useNavigate } from "react-router-dom";
import { formatDistance, isYesterday, isToday, differenceInDays, format } from 'date-fns'
import { crystalFromDream } from '../dream/helpers';

function DreamLogEntry(props) {

  const { dream } = props;
  const navigate = useNavigate();

  const dateHelper = useMemo(() => {
    const createdToday = isToday(dream.createdAt);
    const createdYesterday = isYesterday(dream.createdAt);
    const createdInLastWeek = differenceInDays(dream.createdAt, new Date()) > -7

    if(createdToday) {
      return formatDistance(dream.createdAt, new Date(), { addSuffix: true })
    } else if (createdYesterday) {
      return "Yesterday"
    } else if (createdInLastWeek) {
      return format(dream.createdAt, 'EEEE')
    } else {
      return format(dream.createdAt, 'MMMM do')
    }
  }, [dream.createdAt])

  const crystalHelper = useMemo(() => {
    return crystalFromDream(dream.interpretation);
  }, [dream.interpretation])


  return (<div
    onClick={() => navigate(`${DREAM_ROUTE}/${dream.id}`)}
    className="flex flex-row cursor-pointer"
  >
    <div className="flex w-[23%] justify-center items-center">
      <img className="flex mb-[1em] w-[64px] h-[64px]" src={crystalHelper} alt="ruby" />
    </div>
    <div className="flex flex-col w-[77%]">
      <p className="dream-log-entry-date">{dateHelper}</p>
      <p className="dream-log-entry-text h-[3em] pr-[17px]">{dream.text}</p>
      <div className="bg-[#8F91AF]/[0.3] h-[1px] w-full mt-[16px] mb-[8px]"></div>
    </div>
  </div>)
}

export default DreamLogEntry
