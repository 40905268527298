import PaywallPricing from '../features/payments/PaywallPricing';
import React, { useMemo } from "react";
import {useSelector} from "react-redux";
import {getHasDreams} from "../features/dreamLog/dreamLogSlice";

function Paywall() {

  const hasDreams = useSelector(getHasDreams)

  const headline1 = useMemo(() => {
    if(hasDreams) {
      return "You get one dream for free."
    } else {
      return "Keep track of your dreams."
    }
  }, [hasDreams])

  const headline2 = useMemo(() => {
    if(hasDreams) {
      return "Go unlimited now."
    } else {
      return "Make sense of your dreams."
    }
  }, [hasDreams])

  return (
      <div className={"h-full w-full text-center flex flex-col"}>
        <div className={"h-full w-full text-center flex flex-col overflow-y-auto"}>
          <h1 className={"flex mx-auto paywall-hero-text"}>{headline1}</h1>
          {hasDreams ? null : <h1 className={"flex mx-auto paywall-hero-text"}>{headline2}</h1>}
          <div className={"flex mx-auto flex-col px-[20px] mt-8px]"}>
            <p className={"paywall-description-subtitle"}>Go unlimited for personal dream interpretations, unlimited storage, and daily reminders.</p>
          </div>
          <div className={"flex mx-auto mt-[24px]"}>
            <PaywallPricing />
          </div>
        </div>
      </div>
  )
}

export default Paywall
