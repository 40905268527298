class Dream {
  constructor (id, text, interpretation, createdAt) {
    this.id = id;
    this.text = text;
    this.interpretation = interpretation;
    this.createdAt = createdAt;
  }

  toJson() {
    return {
      id: this.id,
      text: this.text,
      interpretation: this.interpretation,
      createdAt: this.createdAt.getTime()
    }
  }

  static fromJson(jsonDream) {
    return new Dream(
      jsonDream.id,
      jsonDream.text,
      jsonDream.interpretation,
      new Date(jsonDream.createdAt)
    )
  }
}

export function firestoreToDream(firestoreDream) {
  const id = firestoreDream.id;
  const data = firestoreDream.data();
  return new Dream(
    id,
    data.content,
    data.interpretation,
    data.createdAt.toDate()
  )
}

export default Dream;
