import amethyst from '../../assets/crystals/amethyst.png'
import citrine from '../../assets/crystals/citrine.png'
import emerald from '../../assets/crystals/emerald.png'
import hematite from '../../assets/crystals/hematite.png'
import phenakite from '../../assets/crystals/phenakite.png'
import quartz from '../../assets/crystals/quartz.png'
import ruby from '../../assets/crystals/ruby.png'
import sapphire from '../../assets/crystals/sapphire.png'
import tourmaline from '../../assets/crystals/tourmaline.png'

export function crystalFromDream(interpretation) {

  const character15 = interpretation[15]

  switch (character15) {
    case "a":
    case "b": return amethyst;
    case "c":
    case "d":
    case "e": return citrine;
    case "f":
    case "g": return emerald;
    case "h":
    case "i":
    case "j": return hematite;
    case "k":
    case "l":
    case "m":
    case "n": return phenakite;
    case "o":
    case "p":
    case "q":
    case "r": return quartz;
    case "s":
    case "t": return ruby;
    case "u":
    case "v": return sapphire;
    case "w":
    case "x":
    case "y":
    case "z": return tourmaline;
    default: return quartz
  }

}
