import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { db } from '../../index';
import { onSnapshot, collectionGroup, collection, where, query, orderBy, getDocs, addDoc } from "firebase/firestore";
import {loadStripe} from '@stripe/stripe-js';
import {ACCOUNT_ROUTE, SUCCESSFUL_PURCHASE_ROUTE} from '../../app/routes';
import { getAuth } from "firebase/auth";


const initialState = {
  products: null,
  isLoading: false,
  subscription: null,
  isLoadingSubscription: false,
  hasLoadedSubscription: false,
  paywallOpen: false,
}

async function fetchPrices(products) {
  var productList = []
  products.forEach(p => {
    const data = p.data()
    productList.push({
      id: p.id,
      name: data.name,
      description: data.description,
    })
  })

  const fullList = await Promise.all(productList.map(async doc => {
    const prices = await getDocs(query(
      collectionGroup(db, "prices"),
      where("product", "==", doc.id),
      orderBy("unit_amount")
    ))

    var prcs = [];

    prices.docs.forEach((doc) => {
      const priceJson = priceDocToJson(doc)

      if(doc.data().active) {

        prcs.push(priceJson)
      }
    })

    const p = {
      name: doc.name,
      description: doc.description,
      prices: prcs
    }

    return p
  }))

  return { productList: fullList };
}

const priceDocToJson = (priceDoc) => {
  const priceData = priceDoc.data()
  return {
    priceId: priceDoc.id,
    currency: priceData.currency,
    interval: priceData.interval,
    amount: priceData.unit_amount
  }
}

export const fetchAllProducts = createAsyncThunk(
  'payments/fetchProducts',
  async (args) => {
    try {
      const products = await getDocs(query(
        collection(db, "products"),
        where("active", "==", true)
      ))

      const productList = await fetchPrices(products)

      return productList;
    } catch (e) {
      console.error(">>>> ERROR fetching products: ", e)
    }
  }
)

export const initCheckout = createAsyncThunk(
  'payments/initCheckout',
  async (args) => {
    const { priceId, uid } = args;
    try {

      const docRef = await addDoc(
        collection(db, `customers/${uid}/checkout_sessions`),
        {
          price: priceId,
          allow_promotion_codes: true,
          success_url: window.location.origin+SUCCESSFUL_PURCHASE_ROUTE,
          cancel_url: window.location.origin+ACCOUNT_ROUTE
        },
        { merge: true }
      )
      // Wait for the CheckoutSession to get attached by the extension

      onSnapshot(docRef, async (snap) => {

        const { sessionId } = snap.data();
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
          stripe.redirectToCheckout({ sessionId });
        }
      });



    } catch (e) {
      console.error(">>>> ERROR initializing checkout: ", e)
    }
  }
)

export const fetchSubscription = createAsyncThunk(
  'payments/fetchSubscription',
  async () => {
    try {

      const entitlements = await getAuth().currentUser.getIdTokenResult(true).then(token => {
        return token.claims.revenueCatEntitlements
      })

      if(entitlements && entitlements.includes("premium")){
        return { subscription: true }
      } else {
        return { subscription: false }
      }

    } catch (e) {
      console.error("ERROR fetching subscription: ", e)
    }
  }
)

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    resetProducts: (state, action) => {
      state.products = [];
    },
    openPaywall: (state) => {
      state.paywallOpen = true
    },
    closePaywall: (state) => {
      state.paywallOpen = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.isLoading = true;

        const { productList } = action.payload;
        state.products = productList;
      })
      .addCase(fetchSubscription.pending, (state) => {
        state.isLoadingSubscription = true;
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        if(action.payload) {
          const { subscription } = action.payload;

          state.subscription = subscription;
        } else {
          state.subscription = false
        }
        state.isLoadingSubscription = false;
        state.hasLoadedSubscription = true;
      })
  }
})

export const { resetProducts, closePaywall, openPaywall } = paymentsSlice.actions;

export const getIsLoading = (state) => state.payments.isLoading;

export const getProducts = (state) => state.payments.products;

export const getSubscription = (state) => state.payments.subscription;

export const getIsLoadingSubscription = (state) => state.payments.isLoadingSubscription;

export const getPaywallOpen = (state) => state.payments.paywallOpen;

export const getHasLoadedSubscription = (state) => state.payments.hasLoadedSubscription

export default paymentsSlice.reducer;
