import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
  resetDream,
  saveDream,
  getDreamId,
  getIsSaving,
  setDream,
} from '../features/dream/dreamSlice';
import {
  interpretDream,
  getIsLoading,
  getInterpretation,
  resetInterpretation,
} from '../features/interpreter/interpreterSlice';
import {
  getUUID
} from '../features/user/userSlice';
import { DREAM_ROUTE } from '../app/routes';
import Loader from '../components/Loader';
import {usePaymentLogic} from "../features/payments/usePaymentDrawer";
import {openPaywall} from "../features/payments/paymentsSlice";

export function Psychic() {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const uuid = useSelector(getUUID);

  const isInterpreting = useSelector(getIsLoading);
  const interpretation = useSelector(getInterpretation);

  const isSaving = useSelector(getIsSaving);
  const dreamId = useSelector(getDreamId);

  const [dreamText, setDreamText] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const interpretationDone = useMemo(() => {
    return interpretation && !isInterpreting && submitted
  }, [interpretation, isInterpreting, submitted])

  const shouldSave = useMemo(() => {
    return uuid && interpretationDone
  }, [uuid, interpretationDone])

  const saveDone = useMemo(() => {
    return !isSaving && dreamId && shouldSave
  }, [isSaving, dreamId, shouldSave])

  // if user is logged in, save the dream.  Otherwise,
  // have a local dream and navigate to dream page with
  // no dreamId
  // CRITICAL: This should only run once, when
  // interpretationDone is true
  useEffect(() => {
    if(interpretationDone) {
      if(shouldSave) {
        dispatch(saveDream({
          dreamText: dreamText,
          dreamInterpretation: interpretation,
          userId: uuid
        }));
      } else {
        dispatch(setDream({text: dreamText, interpretation}));
        navigate(DREAM_ROUTE);
      }
    }
  // eslint-disable-next-line
  }, [interpretationDone, shouldSave])

  // once saving is complete, navigate to the dream page
  useEffect(() => {
    if(saveDone) {
      navigate(`${DREAM_ROUTE}/${dreamId}`)
    }
  }, [saveDone, navigate, dreamId])

  // on page load, reset the dream data;
  useEffect(() => {
    dispatch(resetInterpretation());
    dispatch(resetDream());
  }, [dispatch])

  const typing = useCallback((text) => {
    setDreamText(text)
  }, [])

  const buttonDisabled = dreamText.length < 40

  const canAddDream = usePaymentLogic()

  const submit = useCallback((e) => {
    if(!buttonDisabled) {
      if(canAddDream) {
        dispatch(interpretDream(dreamText));
        setSubmitted(true);
      } else {
        dispatch(openPaywall())
      }
    }
    e.preventDefault()
  }, [dispatch, dreamText, setSubmitted, buttonDisabled, canAddDream])


  const textSize = useMemo(() => {
    if(dreamText.length < 150){
      return "22px"
    } else if (dreamText.length < 300) {
      return "18px"
    } else if (dreamText.length < 400) {
      return "16px"
    } else if (dreamText.length < 600) {
      return "14px"
    } else {
      return "12px"
    }
  }, [dreamText])

  if(isInterpreting || interpretation) {
    return <Loader />
  }

  return (
    <div className="flex-col w-full">
      <div className="justify-end flex mx-[16px]">
        <button
          className="flex interpret-dream-button w-[106px] h-[40px] justify-center items-center"
          onClick={submit}
          disabled={buttonDisabled}
          href="#"
        >
          <span className="flex">
            {buttonDisabled ? dreamText ? "A bit more..." : "Type dream" : "Interpret" }
          </span>
        </button>
      </div>
      <div className="flex flex-col px-[24px] mt-[51px]">
        <div className="flex overflow-y-auto">
          <textarea
            className={`dream-input
              w-full
              bg-transparent
              border-none outline-none
              resize-none`}
            onChange={e => typing(e.target.value)}
            placeholder={"I dreamt I was a butterfly..."}
            rows={15}
            autoFocus
            style={{fontSize: textSize}}
          />
        </div>
      </div>
    </div>
  );
}
