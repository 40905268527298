import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import { useLocation } from "react-router-dom";
import { ReactComponent as TopLogo } from '../assets/top-logo.svg';
import {
    WELCOME_ROUTE,
    CREATE_DREAM_ROUTE,
    MARKETING_PAGE,
    SUPPORT_PAGE,
    PAYWALL_ROUTE, HOME_ROUTE, SIGNIN_ROUTE
} from '../app/routes';
import {
  getIsLoading,
} from '../features/interpreter/interpreterSlice';
import { useSelector } from 'react-redux';
import star from "../assets/stars.svg";
import mountain from "../assets/bottom-mountains.svg";
import { getAnalytics, logEvent } from "firebase/analytics";
import PaywallDrawer from "../features/payments/PaywallDrawer";
import {useInitialPaywallLogic} from "../features/payments/usePaymentDrawer";


function Layout() {

  const location = useLocation();

  const isWelcomePage = location.pathname === WELCOME_ROUTE;
  const isCreateDreamPage = location.pathname === CREATE_DREAM_ROUTE;
  const isMarketingPage = location.pathname === MARKETING_PAGE;
  const isSupportPage = location.pathname === SUPPORT_PAGE;
  const isPaywallPage = location.pathname === PAYWALL_ROUTE;
  const isHomePage = location.pathname === HOME_ROUTE;
  const isSignInPage = location.pathname === SIGNIN_ROUTE;
  const isInterpreting = useSelector(getIsLoading);


  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, "page_view", {
      path: location.pathname,
    });
  }, [location])

    useInitialPaywallLogic({location})

  return (
    <div>
      <div className="fixed app-background h-screen w-full z-1">
        <div
          className="fixed w-full h-[89%] mt-[28px]"
          style={{
            backgroundImage: `url(${star})`
          }}
          ></div>
        <div
          className="fixed bottom-0 h-[130px] w-full"
          style={{
            backgroundImage: `url(${mountain})`,
            backgroundRepeat: "repeat-x"
          }}
        ></div>
      </div>
      <div

        className="h-screen fixed bg-blue-500 z-10 flex-col"
      >
        {<header className={`h-[91px] fixed w-screen z-11 ${isCreateDreamPage ? "hidden" : ""}`}>
          <TopLogo className={`mx-auto ${isWelcomePage ? "mt-[75px] rotate-[-4deg]" : "mt-[49px]" } ${isWelcomePage ? "h-[71.66px] w-[171.91px]" : ""}`} />
        </header>}
        <main
          className={`h-full fixed w-screen flex flex-col pb-[79px] ${isWelcomePage ? "pt-[113px]" : isCreateDreamPage ? "pt-[50px]" : "pt-[100px]"}`}
        >
          <div className="flex h-full">
            <Outlet />
          </div>
        </main>
        {<footer className={`h-[79px] fixed bottom-0 w-screen ${(isInterpreting || isHomePage || isMarketingPage || isSupportPage || isPaywallPage || isSignInPage) ? "hidden" : ""}`}>
          <Footer />
        </footer>}
      </div>
      <PaywallDrawer />
    </div>
  )
}

export default Layout
