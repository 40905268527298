import React from 'react';
import { useSelector } from 'react-redux';
import {
  getUser,
  getDisplayName,
} from '../features/user/userSlice';
import Pricing from '../features/payments/Pricing';
import { getAuth } from "firebase/auth";
import {getSubscription} from "../features/payments/paymentsSlice";


function Account() {
  const auth = getAuth();

  const user = useSelector(getUser);

  const displayName = useSelector(getDisplayName);

  const subscription = useSelector(getSubscription)

  if (user) {

    return (
      <div className="flex flex-col mx-[24px] w-screen pt-[39px]">
        <div className="flex flex-row">
          <p>You are signed in as {displayName}.</p>
          <button
            className={`
              ring ring-1 ring-[#FFFFFF]/[0.4]
              rounded-full
              h-[2em]
              justify-center items-center
              share-button
              flex
              w-[100px]
              align-right
              ml-auto
              `}
            onClick={() => auth.signOut()}
          >
            Sign-out
          </button>
        </div>
        {subscription ? <Pricing /> : null}

        <div className="flex">

        </div>
      </div>
    )

  }


}

export default Account
