import {useEffect, useMemo, useState} from "react";
import {Drawer} from "flowbite";
import {useDispatch, useSelector} from "react-redux";
import {closePaywall, getHasLoadedSubscription, getSubscription, openPaywall} from "./paymentsSlice";
import {getHasDreams, getHasLoadedHasDreams} from "../dreamLog/dreamLogSlice";
import {HOME_ROUTE} from "../../app/routes";
import {getUser} from "../user/userSlice";


function usePaymentDrawer() {

    const dispatch = useDispatch()

    const [drawer, setDrawer] = useState(null)

    useEffect(() => {
        if(!drawer && dispatch) {
            const $targetEl = document.getElementById("paywall-drawer");

            const options = {
                placement: 'bottom',
                backdrop: true,
                bodyScrolling: true,
                edge: false,
                edgeOffset: '',
                onHide: () => {
                    dispatch(closePaywall())
                },
                onShow: () => {
                    dispatch(openPaywall())
                }
            }

            const drawer = new Drawer($targetEl, options)
            drawer.hide()

            setDrawer(drawer)
        }
    }, [drawer, dispatch])

    return drawer
}

export function usePaymentLogic() {

    const hasDreams = useSelector(getHasDreams);
    const subscription = useSelector(getSubscription)
    const hasLoadedHasDream = useSelector(getHasLoadedHasDreams);
    const user = useSelector(getUser)

    const canAddDream = useMemo(() => {
        //!user is here such that the typical redirect to sign in flow takes over
        return !user || subscription || (hasLoadedHasDream && !hasDreams)
    }, [hasDreams, subscription, hasLoadedHasDream, user])

    return canAddDream
}

export function useInitialPaywallLogic({location}) {
    const hasLoadedSubscription = useSelector(getHasLoadedSubscription)
    const subscription = useSelector(getSubscription)

    const dispatch = useDispatch()

    const [opened, setOpened] = useState(false)

    useEffect(() => {
        const isHomeRoute = location.pathname === HOME_ROUTE;
        if(hasLoadedSubscription && !subscription && !isHomeRoute && !opened) {
            dispatch(openPaywall())
            setOpened(true)
        }
    }, [hasLoadedSubscription, subscription, dispatch, location.pathname, opened])
}

export default usePaymentDrawer