class User {
  constructor (uid, displayName, email, phoneNumber) {
    this.uid = uid;
    this.displayName = displayName;
    this.email = email;
    this.phoneNumber = phoneNumber;
  }

  toJson() {
    return {
      uid: this.uid,
      displayName: this.displayName,
      email: this.email,
      phoneNumber: this.phoneNumber
    }
  }
}

export function firestoreToUser(firestoreUser) {
  return new User(
    firestoreUser.uid,
    firestoreUser.displayName,
    firestoreUser.email,
    firestoreUser.phoneNumber
  )
}

export default User
