import React, { useCallback } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { SIGNIN_ROUTE } from '../app/routes';

function LoginButton() {

  const navigate = useNavigate()
  const location = useLocation()

  const onClick = useCallback((e) => {
    navigate(SIGNIN_ROUTE, {
      state: { from:  location }
    })
    e.preventDefault()
  }, [navigate, location])

  return (<button
    onClick={onClick}
    className={`flex-row
      my-[53px]
      flex
      ring ring-1 ring-[#FFFFFF]/[0.4]
      rounded-full
      h-[2.5em]
      justify-center items-center
      share-button
      w-full
   `}>
    Login to save and share your dream!
  </button>)
}

export default LoginButton;
