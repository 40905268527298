import { useNavigate } from "react-router-dom";
import { CREATE_DREAM_ROUTE } from '../app/routes';

function Welcome() {

  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate(CREATE_DREAM_ROUTE)}
      className="flex flex-col h-full w-full text-center mt-[15vh] px-[10%]">
        <h2 className="flex mx-auto welcome-hero-text">What did you dream?</h2>
        <p className="flex mx-auto mt-3px text-center wecome-body-text">The more detail, the better</p>
    </div>
  )

}

export default Welcome;
