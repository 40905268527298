import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDreamLog,
  getLastVisibleDream,
  getDreams
} from '../features/dreamLog/dreamLogSlice';
import {
  getUUID
} from '../features/user/userSlice';
import DreamLogEntry from '../features/dreamLog/DreamLogEntry';
import Welcome from "./Welcome";

function DreamLog() {

  const dispatch = useDispatch();

  const uuid = useSelector(getUUID);
  const lastVisibleDream = useSelector(getLastVisibleDream);

  const dreams = useSelector(getDreams);

  // Initial load
  useEffect(() => {
    if(uuid) {
      dispatch(getDreamLog({uuid: uuid, lastVisible: lastVisibleDream}));
    }
  }, [uuid, dispatch, lastVisibleDream])

  if(!dreams.length) {
    return <Welcome />
  }

  return (
    <div className="flex flex-col overflow-y-auto pt-[39px]">
      {dreams.map(dream => <DreamLogEntry key={dream.id} dream={dream} />)}
    </div>
  )
}

export default DreamLog;
