import Paywall from "../../pages/Paywall";
import usePaymentDrawer from "./usePaymentDrawer";
import {useCallback, useEffect} from "react";
import { getPaywallOpen} from "./paymentsSlice";
import {useSelector} from "react-redux";


function PaywallDrawer() {

    const paywallOpen = useSelector(getPaywallOpen)

    const drawer = usePaymentDrawer()

    const closeDrawer = useCallback(() => {
        if(drawer) {
            drawer.hide()
        }
    }, [drawer])

    useEffect(() => {
        if(drawer) {
            if (paywallOpen) {
                drawer.show()
            } else {
                drawer.hide()
            }
        }
    }, [drawer, paywallOpen])

    return (
        <div id="paywall-drawer"
             className="py-[48px] bg-[#30315B] fixed bottom-0 left-0 right-0 z-40 w-full overflow-y-auto transition-transform dark:bg-gray-800 transform-none rounded-t-[24px]"
             tabIndex="-1"
        >
            <button
                type="button"
                aria-controls="drawer-bottom-example"
                className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={closeDrawer}
            >
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    ></path>
                </svg>
                <span className="sr-only">Close menu</span>
            </button>
            <Paywall />
        </div>
    )
}

export default PaywallDrawer