import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getUser,
  getIsLoading,
} from './features/user/userSlice';
import {
  getIsLoadingSubscription,
} from './features/payments/paymentsSlice';
import {
  SIGNIN_ROUTE,
  ACCOUNT_ROUTE,
} from './app/routes';
import Loader from './components/Loader';

export function PrivateRoute({ children, ...rest }) {

  const user = useSelector(getUser);
  const isLoading = useSelector(getIsLoading);
  const isLoadingSubscription = useSelector(getIsLoadingSubscription)

  let location = useLocation();
  if(user) {
    return children
  } else if (isLoading || isLoadingSubscription) {
    return <Loader />;
  } else {
    return (<Navigate
      to={SIGNIN_ROUTE}
      state={{ from: location }}
    />)
  }
};

export function PublicOnlyRoute({ children, ...rest }) {

  const user = useSelector(getUser);
  const isLoading = useSelector(getIsLoading);

  let location = useLocation();

  const from = location?.state?.from?.pathname;

  if (isLoading) {
    return <Loader />
  } else if (!user) {
    return children;
  } else {
    return (<Navigate
      to={{
        pathname: from ?? ACCOUNT_ROUTE
      }}
    />)
  }
}
