

function Support() {

  return (
    <div>
      <p>For support please contact us at support@nightcap.guru</p>
    </div>
  )
}

export default Support
