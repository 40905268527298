import Loader from "../components/Loader";

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchSubscription, getSubscription} from "../features/payments/paymentsSlice";
import {Navigate} from "react-router-dom";
import {ACCOUNT_ROUTE} from "../app/routes";

const useInterval = (fn, timeout = 0) => {
    const timerRef = useRef();

    const fnRef = useRef(fn);
    fnRef.current = fn;

    useEffect(() => {
        timerRef.current = window.setInterval(
            () => {
                fnRef.current();
            },
            timeout < 0 ? 0 : timeout
        );

        return () => {
            window.clearInterval(timerRef.current);
        };
    }, [timeout]);

    const clear = useCallback(() => {
        window.clearInterval(timerRef.current);
    }, []);

    return clear;
};
function SuccessfulPurchase() {

    const dispatch = useDispatch()
    const subscription = useSelector(getSubscription)
    const [count, setCount] = useState(0)

    useInterval(
        () => {
            dispatch(fetchSubscription())
            setCount(count + 1)
        },
        subscription ? null : 1000
    )

    if(subscription){
        return (<Navigate
            to={ACCOUNT_ROUTE}
        />)
    }

    return (
            <Loader />
    )
}

export default SuccessfulPurchase