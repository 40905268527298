import { useNavigate, useLocation } from "react-router-dom";
import {
  DREAM_LOG_ROUTE,
  CREATE_DREAM_ROUTE,
  ACCOUNT_ROUTE
} from '../app/routes';
import {
  fetchHasDream,
  getHasDreams,
} from "../features/dreamLog/dreamLogSlice";
import {
  useDispatch, useSelector
} from "react-redux";
import { ReactComponent as DreamLogInactiveIcon } from '../assets/icons/dream-log-inactive.svg';
import { ReactComponent as AccountInactiveIcon } from '../assets/icons/account-inactive.svg';
import { ReactComponent as DreamLogActiveIcon } from '../assets/icons/dream-log-active.svg';
import { ReactComponent as AccountActiveIcon } from '../assets/icons/account-active.svg';
import {useCallback, useEffect} from "react";
import {getUUID} from "../features/user/userSlice";
import {getSubscription} from "../features/payments/paymentsSlice";
import {
  openPaywall
} from "../features/payments/paymentsSlice";
import {usePaymentLogic} from "../features/payments/usePaymentDrawer";

function Footer() {


  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const isAccountPage = location.pathname === ACCOUNT_ROUTE;
  const isDreamLogPage = location.pathname === DREAM_LOG_ROUTE;

  const uuid = useSelector(getUUID);
  const hasDreams = useSelector(getHasDreams);
  const subscription = useSelector(getSubscription)

  useEffect(() => {
    if(uuid && !hasDreams && !subscription) {
      dispatch(fetchHasDream({uuid}))
    }
  }, [location.pathname, uuid, hasDreams, subscription, dispatch])

  const canAddDream = usePaymentLogic()

  const addDreamButton = useCallback(() => {
    if(subscription || canAddDream) {
      navigate(CREATE_DREAM_ROUTE)
    } else {
      dispatch(openPaywall())
    }
  }, [canAddDream, navigate, dispatch, subscription])

  return (<div className="flex flex-col text-center footer-div h-full">
    <div className="mx-auto h-0 relative z-99">
      <button className="flex justify-center items-center special-button mx-auto -mt-[28px] rounded-full w-[72px] h-[72px]"
          onClick={(e) => {
            e.preventDefault()
            addDreamButton()
          }}
      >
        <div className="w-[24px] h-[24px] relative opacity-50 mx-auto">
          <div className="absolute w-[2.67px] h-full bg-[#FFFFFF] rounded-[4px] rotate-90 ml-[10.665px]"></div>
          <div className="absolute w-[2.67px] h-full bg-[#FFFFFF] rounded-[4px] ml-[10.665px]"></div>
        </div>
      </button>
    </div>
    <div className="flex my-auto h-full flex-row text-[#FFFFFF]" >
      <button
        className={`flex w-2/5 items-center ${isDreamLogPage ? "" : "opacity-60"}`}
        onClick={(e) => {
          e.preventDefault()
          navigate(DREAM_LOG_ROUTE)
        }}
      >
        <span className="w-[51%]"></span>
        <span className="-ml-[11px] -mt-[10px]">{isDreamLogPage ? <DreamLogActiveIcon /> : <DreamLogInactiveIcon />}</span>
      </button>
      <span className="flex w-1/5">
      </span>
      <button
        className={`flex w-2/5 items-center ${isAccountPage ? "" : "opacity-60"}`}
        onClick={(e) => {
          e.preventDefault()
          navigate(ACCOUNT_ROUTE)
        }}
      >
        <span className="w-[49%]"></span>
        <span className="-ml-[10px] -mt-[10px]">{isAccountPage ? <AccountActiveIcon /> : <AccountInactiveIcon />}</span>
      </button>
    </div>
  </div>);
}

export default Footer;
