import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Psychic } from './pages/Psychic';
import SignIn from './pages/SignIn';
import Account from './pages/Account';
import Dream from './pages/Dream';
import DreamLog from './pages/DreamLog';
import Welcome from './pages/Welcome';
import Marketing from './pages/Marketing';
import Support from './pages/Support';
import Paywall from './pages/Paywall';
import {
  setUser,
} from './features/user/userSlice';
import {
  getInterpretation,
  getText,
  getDreamId,
  saveDream,
} from './features/dream/dreamSlice';
import {
  fetchSubscription,
} from './features/payments/paymentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PrivateRoute, PublicOnlyRoute } from './PrivateRoute';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  ACCOUNT_ROUTE,
  CREATE_DREAM_ROUTE,
  DREAM_LOG_ROUTE,
  DREAM_ROUTE,
  SIGNIN_ROUTE,
  WELCOME_ROUTE,
  MARKETING_PAGE,
  SUPPORT_PAGE,
  PAYWALL_ROUTE,
  HOME_ROUTE, SUCCESSFUL_PURCHASE_ROUTE
} from './app/routes';
import { firestoreToUser } from './classes/User';
import Layout from './components/Layout';
import SuccessfulPurchase from "./pages/SuccessfulPurchase";

function App() {

  const auth = getAuth();

  const dispatch = useDispatch();

  const dreamText = useSelector(getText);
  const dreamInterpretation = useSelector(getInterpretation);
  const dreamId = useSelector(getDreamId);

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      await user.getIdToken(true);

      dispatch(setUser(firestoreToUser(user).toJson()));
      dispatch(fetchSubscription());
      // if there is an unsaved dream...
      // kind of weird to be here but whatever...
      if(dreamText && dreamInterpretation && !dreamId) {
        dispatch(saveDream({ dreamText, dreamInterpretation, userId: user.uid }))
      }
    } else {
      dispatch(setUser(null));
    }
  });

  return <Router>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route exact path={HOME_ROUTE} element={<Marketing />} />
        <Route exact path={MARKETING_PAGE} element={<Marketing />} />
        <Route exact path={WELCOME_ROUTE} element={
          <PrivateRoute>
              <Welcome />
          </PrivateRoute>
        } />
        <Route exact path={CREATE_DREAM_ROUTE} element={
          <PrivateRoute>
            <Psychic />
          </PrivateRoute>
        } />
        <Route exact path={`${DREAM_ROUTE}/:dreamId`} element={<Dream />} />
        <Route exact path={DREAM_ROUTE} element={
          <PrivateRoute>
            <Dream />
          </PrivateRoute>
        } />
        <Route exact path={SUPPORT_PAGE} element={<Support />} />
        <Route exact path={SIGNIN_ROUTE} element={
          <PublicOnlyRoute>
            <SignIn />
          </PublicOnlyRoute>
        } />
        <Route exact path={ACCOUNT_ROUTE} element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        } />
        <Route exact path={DREAM_LOG_ROUTE} element={
          <PrivateRoute>
            <DreamLog />
          </PrivateRoute>
        } />
        <Route exact path={PAYWALL_ROUTE} element={
          <PrivateRoute>
            <Paywall />
          </PrivateRoute>
        } />
        <Route exact path={SUCCESSFUL_PURCHASE_ROUTE} element={
          <PrivateRoute>
            <SuccessfulPurchase />
          </PrivateRoute>
        } />
        </Route>
    </Routes>
  </Router>
}

export default App;
