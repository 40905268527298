import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import firebase from 'firebase/compat/app';
import { firebaseApiKey } from './globals';
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: "dream-guru-d2cbe.firebaseapp.com",
  projectId: "dream-guru-d2cbe",
  storageBucket: "dream-guru-d2cbe.appspot.com",
  messagingSenderId: "538759222109",
  appId: "1:538759222109:web:c69b943cb34946f8d8ceb4",
  measurementId: "G-F2KV0HWKZZ"
};

export const app = firebase.initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);

if(! analytics) {
  console.error("Problem attaching analytics")
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
      <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
